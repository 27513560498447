import * as React from 'react'
import BaseLayoutTool from '../../components/base-layout-tool'

const HashFilesPage = () => {
    return (
        <BaseLayoutTool 
        pageTitle="Hash Files | App " 
        pageDescription="Produces a hash of each uploaded file using this TNNL tool. Includes SHA1, SHA256 and BLAKE2B hashes and more."
        toolLink="https://filehash-ne2vtntboq-nw.a.run.app"
        />
    )
}

export default HashFilesPage